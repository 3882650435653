import PropTypes from 'prop-types';
import Icon from './Icon';

export default function IcImage({variant, className, stroke, fill}) {
  const VARIANTS = {
    multi: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.8419 11C16.8093 11 18.7391 12.0854 20.4921 13.6681L21.2014 14.2829V17.2L20.8014 19.2L19.6014 20.4L12.8014 20.8L4.40137 20.4V19.6566C6.91257 15.5847 10.7921 11 14.8419 11Z"
          fill={fill}
        />
        <path
          d="M5.85359 4.44007C5.79016 4.44701 5.72674 4.45396 5.66332 4.46089C4.71972 4.56408 3.96128 5.31472 3.84961 6.25424C3.62375 8.15448 3.40137 10.0587 3.40137 12C3.40137 13.9413 3.62375 15.8455 3.84961 17.7458C3.96128 18.6853 4.71972 19.4359 5.66332 19.5391C5.72675 19.546 5.79019 19.553 5.85364 19.5599C7.85907 19.7795 9.87364 20 11.9308 20C13.9879 20 16.0025 19.7795 18.0079 19.5599C18.0714 19.553 18.1348 19.546 18.1982 19.5391C19.1418 19.4359 19.9003 18.6853 20.0119 17.7458C20.2378 15.8455 20.4602 13.9413 20.4602 12C20.4602 10.0587 20.2378 8.15448 20.0119 6.25424C19.9003 5.31472 19.1418 4.56408 18.1982 4.46089C18.1348 4.45396 18.0714 4.44701 18.008 4.44007C16.0025 4.22054 13.9879 4 11.9308 4C9.87362 4 7.85903 4.22054 5.85359 4.44007ZM5.67137 2.44806C7.65104 2.23123 9.76207 2 11.9308 2C14.0995 2 16.2105 2.23123 18.1902 2.44806C18.2655 2.45631 18.3407 2.46455 18.4157 2.47274C20.2817 2.67681 21.7761 4.15111 21.998 6.01819C22.2221 7.90407 22.4602 9.92239 22.4602 12C22.4602 14.0776 22.2221 16.0959 21.998 17.9818C21.7761 19.8489 20.2817 21.3232 18.4157 21.5273C18.3407 21.5355 18.2655 21.5437 18.1901 21.5519C16.2105 21.7688 14.0995 22 11.9308 22C9.7621 22 7.65108 21.7688 5.67143 21.5519C5.59606 21.5437 5.52089 21.5355 5.4459 21.5273C3.5799 21.3232 2.08551 19.8489 1.86359 17.9818C1.63944 16.0959 1.40137 14.0776 1.40137 12C1.40137 9.92239 1.63944 7.90407 1.86359 6.01819C2.08551 4.15111 3.5799 2.67681 5.4459 2.47274C5.52087 2.46455 5.59602 2.45631 5.67137 2.44806Z"
          fill={stroke}
        />
        <path
          d="M8.40137 10C8.9318 10 9.44051 9.78929 9.81558 9.41421C10.1907 9.03914 10.4014 8.53043 10.4014 8C10.4014 7.46957 10.1907 6.96086 9.81558 6.58579C9.44051 6.21071 8.9318 6 8.40137 6C7.87093 6 7.36223 6.21071 6.98715 6.58579C6.61208 6.96086 6.40137 7.46957 6.40137 8C6.40137 8.53043 6.61208 9.03914 6.98715 9.41421C7.36223 9.78929 7.87093 10 8.40137 10Z"
          fill={stroke}
        />
        <path
          d="M3.50932 19.1054C3.40714 19.271 3.35303 19.4619 3.35303 19.6565V20.1353C3.35303 20.4906 3.54152 20.8192 3.8482 20.9985C3.89191 21.0241 3.93615 21.0488 3.9809 21.0728C4.29082 21.2388 4.66514 21.2298 4.96677 21.0492C5.2684 20.8686 5.45303 20.5429 5.45303 20.1913V19.9565C6.65416 18.0432 8.13578 16.0733 9.75917 14.5722C11.4745 12.9861 13.2098 12.05 14.8435 12.05C16.429 12.05 18.1147 12.9348 19.7901 14.4474L19.7988 14.4552L20.5153 15.0763C20.9535 15.4561 21.6167 15.4088 21.9965 14.9706C22.3763 14.5323 22.3289 13.8692 21.8907 13.4894L21.1897 12.8819C19.3607 11.2323 17.1896 9.94995 14.8435 9.94995C12.4275 9.94995 10.1981 11.3062 8.33346 13.0304C6.44803 14.7738 4.79273 17.0243 3.50932 19.1054Z"
          fill={stroke}
        />
      </svg>
    ),
    single: (
      <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.8419 11C16.8093 11 18.7391 12.0854 20.4921 13.6681L21.2014 14.2829V17.2L20.8014 19.2L19.6014 20.4L12.8014 20.8L4.40137 20.4V19.6566C6.91257 15.5847 10.7921 11 14.8419 11Z"
          fill="transparent"
        />
        <path d="M5.85359 4.44007C5.79016 4.44701 5.72674 4.45396 5.66332 4.46089C4.71972 4.56408 3.96128 5.31472 3.84961 6.25424C3.62375 8.15448 3.40137 10.0587 3.40137 12C3.40137 13.9413 3.62375 15.8455 3.84961 17.7458C3.96128 18.6853 4.71972 19.4359 5.66332 19.5391C5.72675 19.546 5.79019 19.553 5.85364 19.5599C7.85907 19.7795 9.87364 20 11.9308 20C13.9879 20 16.0025 19.7795 18.0079 19.5599C18.0714 19.553 18.1348 19.546 18.1982 19.5391C19.1418 19.4359 19.9003 18.6853 20.0119 17.7458C20.2378 15.8455 20.4602 13.9413 20.4602 12C20.4602 10.0587 20.2378 8.15448 20.0119 6.25424C19.9003 5.31472 19.1418 4.56408 18.1982 4.46089C18.1348 4.45396 18.0714 4.44701 18.008 4.44007C16.0025 4.22054 13.9879 4 11.9308 4C9.87362 4 7.85903 4.22054 5.85359 4.44007ZM5.67137 2.44806C7.65104 2.23123 9.76207 2 11.9308 2C14.0995 2 16.2105 2.23123 18.1902 2.44806C18.2655 2.45631 18.3407 2.46455 18.4157 2.47274C20.2817 2.67681 21.7761 4.15111 21.998 6.01819C22.2221 7.90407 22.4602 9.92239 22.4602 12C22.4602 14.0776 22.2221 16.0959 21.998 17.9818C21.7761 19.8489 20.2817 21.3232 18.4157 21.5273C18.3407 21.5355 18.2655 21.5437 18.1901 21.5519C16.2105 21.7688 14.0995 22 11.9308 22C9.7621 22 7.65108 21.7688 5.67143 21.5519C5.59606 21.5437 5.52089 21.5355 5.4459 21.5273C3.5799 21.3232 2.08551 19.8489 1.86359 17.9818C1.63944 16.0959 1.40137 14.0776 1.40137 12C1.40137 9.92239 1.63944 7.90407 1.86359 6.01819C2.08551 4.15111 3.5799 2.67681 5.4459 2.47274C5.52087 2.46455 5.59602 2.45631 5.67137 2.44806Z" />
        <path d="M8.40137 10C8.9318 10 9.44051 9.78929 9.81558 9.41421C10.1907 9.03914 10.4014 8.53043 10.4014 8C10.4014 7.46957 10.1907 6.96086 9.81558 6.58579C9.44051 6.21071 8.9318 6 8.40137 6C7.87093 6 7.36223 6.21071 6.98715 6.58579C6.61208 6.96086 6.40137 7.46957 6.40137 8C6.40137 8.53043 6.61208 9.03914 6.98715 9.41421C7.36223 9.78929 7.87093 10 8.40137 10Z" />
        <path d="M3.50932 19.1054C3.40714 19.271 3.35303 19.4619 3.35303 19.6565V20.1353C3.35303 20.4906 3.54152 20.8192 3.8482 20.9985C3.89191 21.0241 3.93615 21.0488 3.9809 21.0728C4.29082 21.2388 4.66514 21.2298 4.96677 21.0492C5.2684 20.8686 5.45303 20.5429 5.45303 20.1913V19.9565C6.65416 18.0432 8.13578 16.0733 9.75917 14.5722C11.4745 12.9861 13.2098 12.05 14.8435 12.05C16.429 12.05 18.1147 12.9348 19.7901 14.4474L19.7988 14.4552L20.5153 15.0763C20.9535 15.4561 21.6167 15.4088 21.9965 14.9706C22.3763 14.5323 22.3289 13.8692 21.8907 13.4894L21.1897 12.8819C19.3607 11.2323 17.1896 9.94995 14.8435 9.94995C12.4275 9.94995 10.1981 11.3062 8.33346 13.0304C6.44803 14.7738 4.79273 17.0243 3.50932 19.1054Z" />
      </svg>
    ),
  };

  return (
    <Icon className={className} label="Product Image">
      {VARIANTS[variant]}
    </Icon>
  );
}

IcImage.propTypes = {
  className: Icon.propTypes.className,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  variant: PropTypes.string,
};

IcImage.defaultProps = {
  className: '',
  stroke: '#182C53',
  fill: '#E8EDF4',
  variant: 'single',
};
